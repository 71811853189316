body {
  margin: 0;
  overflow-y: scroll; /* Show vertical scrollbar */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f6fffd !important; */
  background: #f9f9f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sim-card-content {
  margin: 0 1rem;
  text-align: center;
}
/* .dashboard-card {
  height: 350px;
} */

.card-action-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.sim-greysmall {
  font-size: 12px;
  color: grey;
}

.sim-error {
  color: red;
}

.sim-text-link {
  cursor: pointer;
}

.sim-text-link:hover {
  text-decoration: underline;
}

.sim-text-field {
  margin-bottom: 10px !important;
}

.sim-select {
  margin-bottom: 10px !important;
}

/* Removing Arrows from Number Input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

.back-link {
  margin-bottom: 10px;
}

.div-btn {
  text-align: center;
  width: 100%;
  background-color: #267353;
  color: white;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
}
